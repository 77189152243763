import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { listEarningRules } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/http';
import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { queryCouponTemplate } from '@wix/ambassador-loyalty-v1-coupon/http';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { getTiersProgram } from '@wix/ambassador-loyalty-v1-tier/http';

import { Experiments } from '../../constants/experiments';

export const loadData = async (flowAPI: ControllerFlowAPI) => {
  const tiersEnabled = flowAPI.experiments.enabled(Experiments.ShowTiers);

  const rewardsAndCouponsPromise = flowAPI.httpClient.request(listRewards({})).then(async ({ data }) => {
    const rewards = data.rewards ?? [];
    let coupons: CouponReference[] = [];

    if (rewards.length) {
      coupons = await getCoupons(flowAPI, rewards);
    }

    return { rewards, coupons };
  });

  const earningRulesPromise = flowAPI.httpClient.request(listEarningRules({}));
  const tiersProgramPromise = tiersEnabled ? flowAPI.httpClient.request(getTiersProgram({})) : Promise.resolve();

  const [{ rewards, coupons }, earningRulesResponse, tiersProgramResponse] = await Promise.all(
    [rewardsAndCouponsPromise, earningRulesPromise, tiersProgramPromise]
  );

  const earningRules = earningRulesResponse.data.earningRules ?? [];
  const hasVipPlan = tiersProgramResponse?.data.vipPlan ?? false;
  const tiersProgramSettings = tiersProgramResponse?.data.programSettings ?? {};
  const tiers = tiersProgramResponse?.data.tiers ?? [];

  return {
    earningRules,
    rewards,
    coupons,
    hasVipPlan,
    tiersProgramSettings,
    tiers,
  };
};

async function getCoupons(flowAPI: ControllerFlowAPI, rewards: Reward[]) {
  let coupons: CouponReference[] = [];
  const templateIds = rewards.filter((reward) => reward.coupon?.templateId).map((reward) => reward.coupon?.templateId);

  if (templateIds.length) {
    const { data } = await flowAPI.httpClient.request(
      queryCouponTemplate({
        query: {
          filter: JSON.stringify({ id: { $in: templateIds } }),
        },
      }),
    );

    if (data.couponReferences) {
      coupons = data.couponReferences;
    }
  }

  return coupons;
}
