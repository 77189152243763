import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { Experiments } from '../../constants/experiments';

export const createRuleDescription = (
  rule: LoyaltyEarningRule,
  flowAPI: ControllerFlowAPI,
  currency: string,
  locale: string,
  loyaltyProgram: LoyaltyProgram,
  configIndex: number,
) => {
  const { t } = flowAPI.translations;
  if (rule.triggerActivityType) {
    switch (rule.triggerActivityType) {
      case 'wix-restaurants/orderSubmitted': {
        const { moneyAmount, points } = rule.conversionRate?.configs![configIndex]!;
        const key = loyaltyProgram.pointDefinition?.customName
          ? 'app.rule-description.conversion-rate-custom'
          : 'app.rule-description.conversion-rate';
        return t(key, {
          pointsName: loyaltyProgram.pointDefinition?.customName,
          pointsAmount: points,
          moneyAmount: new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(moneyAmount ?? 0),
        });
      }

      case 'auth/register': {
        const { points } = rule.fixedAmount?.configs![configIndex]!;
        const key = loyaltyProgram.pointDefinition?.customName
          ? 'app.rule-description.fixed-amount-custom'
          : 'app.rule-description.fixed-amount';

        return t(key, {
          pointsName: loyaltyProgram.pointDefinition?.customName,
          pointsAmount: points,
        });
      }
    }
  }

  if (rule.conversionRate) {
    const { moneyAmount, points } = rule.conversionRate?.configs![configIndex]!;
    const key = loyaltyProgram.pointDefinition?.customName
      ? 'app.rule-description.conversion-rate-custom'
      : 'app.rule-description.conversion-rate';

    return t(key, {
      pointsName: loyaltyProgram.pointDefinition?.customName,
      pointsAmount: points,
      moneyAmount: new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(moneyAmount ?? 0),
    });
  }

  if (rule.fixedAmount) {
    const { points } = rule.fixedAmount?.configs![configIndex]!;
    const key = loyaltyProgram.pointDefinition?.customName
      ? 'app.rule-description.fixed-amount-custom'
      : 'app.rule-description.fixed-amount';

    return t(key, {
      pointsName: loyaltyProgram.pointDefinition?.customName,
      pointsAmount: points,
    });
  }

  return '';
};
